import Dropdown from 'react-bootstrap/Dropdown';
import '../../../styles/MarkerPopup.css'
import { execScript } from '../../../http/complexesApi.js';

const DropdownScripts = (props) => {
    if (props.complex.scripts.length === 0) return null
    return (
        <Dropdown>
          <Dropdown.Toggle variant="warning" id="dropdown-basic">
            <span className='fa fa-terminal'></span>
          </Dropdown.Toggle>
    
          <Dropdown.Menu>
            {
              
              props.complex.scripts.filter(s => s.description === 'map').map((scr) => {
                // console.log(scr)
                const t = () => {
                  execScript(props.complex.hostid, scr.id)
                }
                return <Dropdown.Item key={scr.id} onClick={t}>{scr.name}</Dropdown.Item>
              })
            }
          </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownScripts;