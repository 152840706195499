import { Popup } from "react-leaflet"
import { Button } from "react-bootstrap";
import { getAcknowldgedTriggerUrl, getNote, violationsFixations } from "../helper";
import React from "react"
import { getProblems } from "../helper";
import { useContext } from "react";
import { CurrentComplexContext } from "../../../context";
import { UserContext } from "../../../context";
import DropdownLinks from "./DropdownLinks";
import DropdownScripts from "./DropdownScripts";

const MarkerPopup = props => {
    const { violations, fixations } = violationsFixations(props.complex);
    const { setCurrentComplexPayload } = useContext(CurrentComplexContext)
    const problems = getProblems(props.complex)
    const acknowledge_url= getAcknowldgedTriggerUrl(props.complex)
    // console.log(props.complex)
    // console.log(problems)
    const notes = getNote(props.complex)
    const { userPayload } = useContext(UserContext)
    const { roles } = userPayload
    const infoClick = () => {
        setCurrentComplexPayload({ modal: true, complex: props.complex })
    }
    return (
        <Popup maxWidth={400}>
            <div className='popup_header'>
                <span style={{ fontWeight: 600, textAlign: 'center', width: '100%', maxWidth: '350px' }}>{props.complex.name} </span>
            </div>
            {/* <hr style={{ margin: '5px' }} /> */}
            <div style={{ display: "flex", flexDirection: 'row', marginTop: '2px', marginBottom: '2px', whiteSpace: 'nowrap'}}>
                <span className="popup_hr_line" >-</span>
                <span className="popup_body_badge popup_body_badge_coordinates" onClick={() => window.open(`https://yandex.ru/maps/?whatshere[point]=${props.complex.inventory.location_lon},${props.complex.inventory.location_lat}&whatshere[zoom]=17`)}>{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}</span>
                <span className="popup_hr_line">-</span>
                <span className="popup_hr_text">Координаты</span>
                <hr className="popup_hr" style={{ width: '100%'}}/>
                <span className="popup_hr_text">Проездов</span>
                <span className="popup_hr_line">-</span>
                <span className="popup_body_badge popup_body_badge_fixations">{fixations}</span>
                <span className="popup_hr_line" style={{ paddingBottom: '2px'}}>-</span>
            </div>

            <div style={{ display: "flex", flexDirection: 'row', marginTop: '2px', marginBottom: '2px', whiteSpace: 'nowrap'}}>
                <span className="popup_hr_line" >-</span>
                <span className="popup_body_badge popup_body_badge_date">{(new Date()).toLocaleString('ru')}</span>
                <span className="popup_hr_line">-</span>
                <span className="popup_hr_text">Время</span>
                <hr className="popup_hr" style={{ width: '100%'}}/>
                <span className="popup_hr_text">Нарушений</span>
                <span className="popup_hr_line">-</span>
                <span className="popup_body_badge popup_body_badge_violations">{violations}</span>
                <span className="popup_hr_line" style={{ paddingBottom: '2px'}}>-</span>
            </div>
            {
                notes.length === 0 ? null :
                    <>
                        <div style={{ display: "flex", flexDirection: 'row'}}>
                            <hr className="popup_hr" style={{ width: '100%'}}/>
                            <span className="popup_hr_text">Заметки</span>
                            <hr className="popup_hr" style={{ width: '5px'}}/>
                        </div>
                        { notes.map(note => {
                                return(<div className='popup_body'>
                                    <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: 'cadetblue' }}>{note[1]}</span>
                                </div>)
                        })}
                    </>
            }
            <div style={{ display: "flex", flexDirection: 'row'}}>
                <hr className="popup_hr" style={{ width: '100%'}}/>
                <span className="popup_hr_text">Проблемы</span>
                <hr className="popup_hr" style={{ width: '5px'}}/>
            </div>
            
            {
                problems.length === 0
                    ?
                    <div className='popup_body'>
                        <span className="popup_body_badge popup_body_badge_alerts_good">Работа в штатном режиме</span>
                    </div>
                    : problems.map(element => {
                        return (
                            <div className='popup_body' key={element[0]}>
                                {/* <span className="popup_body_badge popup_body_badge_alerts_host">{element[1].split('/')[1]}</span> */}
                                <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: element[4] }}>{element[2]}: {element[3]}</span>
                                {/* <span className="popup_body_badge popup_body_badge_alerts_duration">{element[3]}</span> */}
                            </div>

                        )
                    })
            }
            <hr style={{ margin: '5px' }} />
            <div className='popup_footer'>
                {roles && !(roles.includes('operator')) ?
                    <div style={{ display: 'flex'}}>
                        <DropdownLinks  complex={props.complex}/>
                        <DropdownScripts complex={props.complex}/>
                    </div>
                    : null
                }
                {/* {acknowledge_url && !(roles.includes('operator')) ?
                    <div style={{ display: 'flex'}}>
                        <Button size="sm" variant="outline-success" href={acknowledge_url}> Битрикс </Button>
                    </div>
                    : null
                } */}
                <div style={{ display: 'flex'}}>
                {acknowledge_url && !(roles.includes('operator')) ?
                    // <div style={{ display: 'flex'}}>
                        <Button size="sm" variant="outline-success" href={acknowledge_url} title="Ссылка на внеплановую задачу в Битрикс"> <i style={{ paddingRight: 3, width: '25px'}} className="fa fa-screwdriver-wrench"></i> </Button>
                    // </div>
                    : null
                }
                {props.complex.bitrix.regular_task ?
                    <Button size="sm" variant="outline-success" onClick={infoClick} title="ССылка на регламентную задачу в Битрикс"> <i style={{ paddingRight: 2, width: '25px'}} className="fa fa-broom"></i> </Button>
                    : null
                }
                
                <Button size="sm" variant="outline-primary" onClick={infoClick}>
                    <svg width="25px" height="18px" viewBox="0 3 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
                        <circle fill="#2196F3" cx="24" cy="24" r="21"/>
                        <rect x="22" y="22" fill="#ffffff" width="4" height="11"/>
                        <circle fill="#ffffff" cx="24" cy="16.5" r="2.5"/>
                    </svg>
                </Button>
                </div>
            </div>
        </Popup>
    )
}


export default MarkerPopup;
