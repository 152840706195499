import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, Row } from 'react-bootstrap';
import { getRSTPFrame, getRTSPTimeLine } from '../../../http/complexesApi';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { unixTimeToString } from '../../../utils/converters';
import '../../../styles/ComplexImages.css'
import { OrbitProgress } from 'react-loading-indicators';

const ComplexImages = props => {
    const coeff = 1000 * 60 * 5;
    const [hostid, setHostid] = useState(null)
    const [timeline, setTimeline] = useState([])
    const [image, setImage] = useState(null)
    const [rounded, setRounded] = useState(new Date(Math.round(new Date().getTime() / coeff) * coeff + coeff))



    // var date = new Date();  //or use any other date
    // var rounded = new Date(Math.round(date.getTime() / coeff) * coeff + coeff)

    var hosts = []
    props.complex.child.map(child => {
        const ax = child.tags.find(t => t.value === 'axis')
        if (ax) {
            hosts = [...hosts, child]
        }
    })
    // console.log(hosts)
    if (hosts.length > 0 && !hostid) {
        setHostid(hosts[0].hostid)
    }
    // setConfig(axis[0])

    useEffect(() => {
        const int = setInterval(() => {
            setRounded(new Date(Math.round(new Date().getTime() / coeff) * coeff + coeff))
            getRTSPTimeLine(hostid).then(res => { setTimeline(res) })
        }, 2 * 60 * 1000)
        return () => clearInterval(int)
    }, [])

    useEffect(() => {
        // console.log(hostid)
        if (hostid) {
            getRTSPTimeLine(hostid).then(res => {
                setTimeline(res)
                // console.log(res)

            }).catch(err => {
                setTimeline([])
            })
        }

    }, [hostid])

    useEffect(() => {
        // console.log(hostid)
        if (timeline && timeline.length > 0) {
            getRSTPFrame(hostid, timeline[timeline.length - 1]).then(res => {
                setImage(res)
                // console.log(res)
            }).catch(err => {
                setImage(null)
            })
        }

    }, [timeline])

    return (
        <Form>
            <Row key={0} style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Select style={{ width: '80%' }} onChange={(e) => {
                    setHostid(e.target.value)
                    // console.log(e.target.value)
                }}>
                    {
                        hosts.map(c => {
                            return <option key={c.hostid} value={c.hostid}>{c.name.split('/')[1]}</option>
                        })
                    }
                </Form.Select>
            </Row>

            <Row key={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <hr style={{ margin: '10px' }} />
                {
                    image ?
                        <div key={2} style={{ width: "100%", height: "450px", display: 'flex', flexDirection: 'column', justifyContent: 'center', border: "1px solib black", alignItems: "center" }}>
                            <PhotoProvider>
                                <PhotoView key={image.time} src={image.img}>
                                    <img key={image.time} src={image.img} width={'100%'} alt='' />
                                </PhotoView>
                            </PhotoProvider>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {
                                    timeline.indexOf(image.time) !== 0 ?
                                        <>
                                            <Button style={{ marginLeft: 30 }} onClick={() => getRSTPFrame(hostid, timeline[0]).then(res => setImage(res))}>В начало</Button>
                                            <Button style={{ marginRight: 30 }} onClick={() => getRSTPFrame(hostid, timeline[timeline.indexOf(image.time) - 1]).then(res => setImage(res))}>Предыдущий кадр</Button>
                                        </>
                                        : null
                                }
                                <span style={{ fontFamily: 'Consolas', paddingTop: 6 }}>{unixTimeToString(image.time / 1000)}</span>
                                {
                                    timeline.indexOf(image.time) !== timeline.length - 1 ?
                                        <>
                                            <Button style={{ marginLeft: 30 }} onClick={() => getRSTPFrame(hostid, timeline[timeline.indexOf(image.time) + 1]).then(res => setImage(res))}>Следующий кадр</Button>
                                            <Button style={{ marginRight: 30 }} onClick={() => getRSTPFrame(hostid, timeline[timeline.length - 1]).then(res => setImage(res))}>В конец</Button>
                                        </>
                                        : null
                                }
                            </div>

                        </div>
                        : <div key={3} style={{ width: "100%", height: "450px", display: 'flex', justifyContent: 'center', border: "1px solib black", alignItems: "center" }}>
                            <OrbitProgress color="green" size="medium" text="" textColor="" />
                        </div>
                }
                <div key={4} style={{ width: '95%', height: 1, backgroundColor: '#000000', marginTop: 10 }} />
                <div key={5} style={{ display: 'flex', flexDirection: 'row', width: '95%', overflowX: 'auto', whiteSpace: 'nowrap', scrollBehavior: 'smooth' }}> {
                    [...Array(289).keys()].reverse().map(i => {
                        // var coeff = 1000 * 60 * 5;
                        // var date = new Date();  //or use any other date
                        // var rounded = new Date(Math.round(date.getTime() / coeff) * coeff + coeff)
                        const dt = new Date(rounded.valueOf() - (i + 1) * 300 * 1000)
                        const start = dt.valueOf() - 300 * 1000
                        const end = dt.valueOf()
                        var time = timeline && timeline.find(time => time < end && time > start)
                        return <div key={i + 10} style={{ display: "flex", flexDirection: "column", alignItems: "center", width: 10 }}>
                            {
                                time ?
                                    (
                                        image && image.time < end && image.time > start ?
                                            <div key={time} className='timeline_point_active' id='active' />
                                            : <div className='timeline_point' onClick={() => getRSTPFrame(hostid, time).then(res => setImage(res))} />
                                    )
                                    : <div className='timeline_point_black' />
                            }
                            <div style={{ width: 1, height: 6, backgroundColor: "#ccc" }} />

                            {
                                i % 4 === 0 ?
                                    <span style={{ fontFamily: "Consolas", fontSize: 12, marginTop: 4, color: "#999" }}>
                                        {dt.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}
                                    </span>
                                    : null
                            }
                            {/* {
                        dt.getMinutes() === 0 && dt.getHours() === 0 ?
                        <span style={{ fontFamily: "Consolas", fontSize: 12, marginTop: 4, color: "#999", fontWeight: 'bold' }}>
                            {dt.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit'})}
                        </span>
                        : null
                    }
                     */}
                        </div>
                    })
                }

                </div>

                {/* <ScaleHour text={'213'} />
       <ScaleQuarter />
        */}

            </Row>

        </Form>

    )


}

export default ComplexImages